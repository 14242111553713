import React, { useState } from 'react';
import axios from 'axios';
import { Modal, Box, Typography, TextField, Button, Grid, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox } from '@mui/material';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const CreateUserModal = ({ isOpen, onClose, onUserAdded, roles }) => {
    const [userData, setUserData] = useState({
      username: '',
      email: '',
      password: '',
      role_id: null,
    });
  
    const handleChange = (e) => {
      setUserData({
        ...userData,
        [e.target.name]: e.target.value
      });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const token = localStorage.getItem('token');
        await axios.post(`${API_BASE_URL}/register`, userData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        alert('Usuario creado con éxito');
        onUserAdded();  // Notifica al componente padre que un usuario fue agregado
        onClose();
      } catch (error) {
        console.error('Hubo un error al crear el usuario:', error);
        alert('Hubo un error al crear el usuario');
      }
    };

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="modal-title-create-user"
            aria-describedby="modal-description-create-user"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography id="modal-title-create-user" variant="h6" component="h2">
                    Crear Nuevo Usuario
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Nombre de Usuario"
                        name="username"
                        autoFocus
                        value={userData.username}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        value={userData.email}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Contraseña"
                        type="password"
                        id="password"
                        value={userData.password}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="phoneNumber"
                        label="Número de Teléfono"
                        name="phoneNumber"
                        value={userData.phoneNumber}
                        onChange={handleChange}
                    />
                    
                    <FormControlLabel
                        control={<Checkbox checked={userData.isActive} onChange={handleChange} name="isActive" />}
                        label="Activo"
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="role-label">Rol</InputLabel>
                        <Select
                            labelId="role-label"
                            id="role_id"
                            name="role_id"
                            value={userData.role}
                            label="Rol"
                            onChange={handleChange}
                        >
                            {roles.map((role) => (
                                <MenuItem key={role.id} value={role.id}>
                                    {role.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid item xs={6}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Crear Usuario
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                variant="outlined"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={onClose}
                            >
                                Cerrar
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Modal>
    );
};

export default CreateUserModal;