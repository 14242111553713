import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid, Button, Modal, Box, Typography, Paper, List, ListItem, ListItemText } from '@mui/material';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const UserEvents = () => {
  const [currentLocation, setCurrentLocation] = useState({ latitude: '', longitude: '' });
  const [events, setEvents] = useState([]);
  const [error, setError] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [processing, setProcessing] = useState({}); // Estado para rastrear qué eventos están siendo procesados

  const fetchEvents = async () => {
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');
    try {
      const { data } = await axios.get(`${API_BASE_URL}/events/user/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setEvents(data);
    } catch (err) {
      setError('Error al obtener los eventos: ' + err.message);
    }
  };

  useEffect(() => {
    const geoSuccess = (position) => {
      setCurrentLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        error: null
      });
    };

    const geoError = (error) => {
      setError('Error al obtener tu ubicación: ' + error.message);
    };

    const geoOptions = {
      maximumAge: 30000,
      timeout: 27000,
      enableHighAccuracy: true
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(geoSuccess, geoError, geoOptions);
    } else {
      setError('La Geolocalización no está soportada por tu navegador.');
    }

    fetchEvents();
  }, []);

  const handleConfirmAttendance = async (eventId, type) => {


    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    try {
      const response = await axios.patch(`${API_BASE_URL}/events/${type}/${eventId}/${userId}`, { currentLocation }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response)
      if (response.status === 200) {
        setProcessing(prev => ({ ...prev, [eventId]: true }));
        setTimeout(() => {
          setProcessing(prev => ({ ...prev, [eventId]: false }));
        }, 30000);
        alert(`${type === 'checkout' ? 'Check Out' : 'Check In'} REALIZADO CON EXITO`);
        fetchEvents();
      } else {
        throw new Error('La operación no fue exitosa');
      }
    } catch (error) {
      alert(`Error al confirmar asistencia: ${error.response?.data?.message || error.message}`);
      fetchEvents();
    }
  };

  const handleViewMore = (event) => {
    setSelectedEvent(event);
    setModalOpen(true);
  };

  return (
    <div>
      <h2>Eventos</h2>
      {error ? <p>{error}</p> : (
        <Grid container spacing={2} sx={{ padding: 2 }}>
          {events.map((event) => (
            <Grid item xs={12} sm={6} md={4} key={event.id}>
              <Paper sx={{ padding: 2, margin: 1 }}>
                <Typography variant="h6" gutterBottom>{event.name}</Typography>
                <Button
                  variant="contained"
                  color={!event.UserEvent.checkIn ? "primary" : "secondary"}
                  fullWidth
                  onClick={() => handleConfirmAttendance(event.id, !event.UserEvent.checkIn ? 'checkin' : 'checkout')}
                  disabled={event.UserEvent.checkOut || processing[event.id]}
                >
                  {!event.UserEvent.checkIn ? `Check In ${event.name}` : `Check Out ${event.name}`}
                </Button>
                <Button
                  variant="contained"
                  color="info"
                  fullWidth
                  onClick={() => handleViewMore(event)}
                  sx={{ marginTop: 1 }}
                >
                  Ver Más
                </Button>
              </Paper>
            </Grid>
          ))}
        </Grid>
      )}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ margin: '15px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
          <Typography id="modal-title" variant="h6" component="h2">
            Detalles del evento: {selectedEvent ? selectedEvent.name : ''}
          </Typography>
          <List dense>
            <ListItem>
              <ListItemText primary="Lugar del Evento" secondary={`${selectedEvent?.Location.name}, ${selectedEvent?.Location.address}`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Lugar de Concentración" secondary={`${selectedEvent?.concentration_place.name}, ${selectedEvent?.concentration_place.address}`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Fecha y Hora" secondary={`${selectedEvent?.date} a las ${selectedEvent?.time}`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Descripción del Evento" secondary={selectedEvent?.description} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Tipo de Evento" secondary={selectedEvent?.eventType} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Rol en el Evento" secondary={selectedEvent?.UserEvent.eventRole === 'owner' ? 'Tecnico Encargado' : 'Tecnico'} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Participantes" secondary={selectedEvent?.participants.map(participant => `${participant.username} (${participant.role === 'owner' ? 'Tecnico Encargado' : 'Tecnico'})`).join(', ')} />
            </ListItem>
          </List>
        </Box>
      </Modal>
    </div>
  );
};

export default UserEvents;
