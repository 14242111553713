import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField, Button, Container, Typography, FormControl, InputLabel, OutlinedInput, FormHelperText, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const ResetPasswordForm = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const { token } = useParams();

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (password === confirmPassword) {
            try {
                await axios.post(`${process.env.REACT_APP_API_BASE_URL}/reset-password/${token}`, { password });
                alert('Tu contraseña ha sido restablecida con éxito');
                navigate('/');
            } catch (error) {
                console.error('Error al restablecer la contraseña:', error);
                alert('No se pudo restablecer la contraseña');
            }
        } else {
            alert('Las contraseñas no coinciden');
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Container component="main" maxWidth="xs">
            <Typography component="h1" variant="h5">
                Restablecer Contraseña
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Nueva Contraseña"
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    autoComplete="new-password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    error={password !== confirmPassword && confirmPassword.length > 0}
                    helperText={(password !== confirmPassword && confirmPassword.length > 0) ? "Las contraseñas no coinciden" : ""}
                />
                <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel htmlFor="confirm-password">Confirmar Contraseña</InputLabel>
                    <OutlinedInput
                        id="confirm-password"
                        type={showPassword ? 'text' : 'password'}
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)}
                        endAdornment={
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        }
                        label="Confirmar Contraseña"
                        error={password !== confirmPassword}
                    />
                    <FormHelperText error={password !== confirmPassword}>
                        {password !== confirmPassword ? "Las contraseñas no coinciden" : ""}
                    </FormHelperText>
                </FormControl>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Restablecer Contraseña
                </Button>
            </form>
        </Container>
    );
};

export default ResetPasswordForm;
