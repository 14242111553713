import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AddLocationForm from './AddLocationForm';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const LocationsPage = () => {
  const [locations, setLocations] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // Estado para controlar la visibilidad del modal

  useEffect(() => {
    fetchLocations();
  }, []);

  const fetchLocations = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_BASE_URL}/locations`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLocations(response.data);
    } catch (error) {
      console.error('Error al cargar las ubicaciones:', error);
    }
  };

  const handleOpenModal = () => setIsModalOpen(true); // Abre el modal

  const handleLocationAdded = () => {
    fetchLocations();
  };
  const handleDeleteLocation = (locationId) => {
    if (window.confirm('¿Estás seguro de querer eliminar esta ubicación?')) {
      // Aquí enviarías una solicitud DELETE a tu backend
      axios.delete(`${API_BASE_URL}/locations/${locationId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then(() => {
          alert('Ubicación eliminada exitosamente');
          fetchLocations();
        })
        .catch(error => {
          console.error('Hubo un error al eliminar la ubicación:', error);
          alert('No se pudo eliminar la ubicación');
        });
    }
  };
  return (
    <div>
      <h2>Crear Ubicacion</h2>
      <Button variant="contained" onClick={handleOpenModal}>Agregar Nueva Ubicación</Button>
      <h2>Ubicaciones</h2>
      <AddLocationForm isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onLocationAdded={handleLocationAdded} />
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Nombre</TableCell>
              <TableCell align="center">Descripción</TableCell>
              <TableCell align="center">Dirección</TableCell>
              <TableCell align="center">Latitud</TableCell>
              <TableCell align="center">Longitud</TableCell>
              <TableCell align="center">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {locations.map((location) => (
              <TableRow key={location.id}>
                <TableCell align="center">{location.name}</TableCell>
                <TableCell align="center">{location.description}</TableCell>
                <TableCell align="center">{location.address}</TableCell>
                <TableCell align="center">{location.latitud}</TableCell>
                <TableCell align="center">{location.longitud}</TableCell>
                <TableCell align="center">
                  <Button variant="contained" color="error" onClick={() => handleDeleteLocation(location.id)}>
                    Eliminar
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default LocationsPage;
