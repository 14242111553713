import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import CreateUserModal from './CreateUserModal'
import './AdminUsers.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import EditUserModal from './EditUserModal';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const AdminUsers = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); 
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null); 
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    fetchUsers();
    fetchRoles();
  }, []);
  const handleEditUser = (user) => {
    setSelectedUser(user);
    setIsEditModalOpen(true);
  };
  

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
  };

  const handleUserUpdated = (updatedUser) => {
    setUsers(users.map(user => user.id === updatedUser.id ? updatedUser : user));
    setIsEditModalOpen(false);
    fetchUsers();
  };
  const handleDeleteUser = async (userId) => {
    if (window.confirm('¿Estás seguro de querer eliminar este usuario?')) {
      try {
        const token = localStorage.getItem('token');
        await axios.delete(`${API_BASE_URL}/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        alert('Usuario eliminado exitosamente');
        fetchUsers();
      } catch (error) {
        console.error('Error al eliminar el usuario:', error);
        alert('No se pudo eliminar el usuario');
      }
    }
  };


  const fetchRoles = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_BASE_URL}/roles`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setRoles(response.data); // Guarda los roles en el estado
    } catch (error) {
      console.error('Error al obtener los roles:', error);
    }
  };

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_BASE_URL}/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers(response.data);
    } catch (error) {
      console.error('Error al obtener los usuarios:', error);
    }
  };


  const handleOpenCreateUserModal = () => {
    setIsCreateUserModalOpen(true);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/');
    }
  }, [navigate]);
  const handleUserAdded = () => {
    fetchUsers();
  };
  return (
    <div>
      <h2>Crear Usuarios</h2>
      <Button variant="contained" color="primary" onClick={handleOpenCreateUserModal}>
        Crear Nuevo Usuario
      </Button>
      {isCreateUserModalOpen && (
        <CreateUserModal isOpen={isCreateUserModalOpen} onClose={() => setIsCreateUserModalOpen(false)} onUserAdded={handleUserAdded} roles={roles} />

      )}
      <div>
        <h2>Lista de Usuarios</h2>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">ID</TableCell>
                <TableCell align="center">Nombre de Usuario</TableCell>
                <TableCell align="center">Telefono</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">Rol</TableCell>
                <TableCell align="center">Estado del Usuario</TableCell>
                <TableCell align="center">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell align="center">{user.id}</TableCell>
                  <TableCell align="center">{user.username}</TableCell>
                  <TableCell align="center">{user.phoneNumber}</TableCell>
                  <TableCell align="center">{user.email}</TableCell>
                  <TableCell align="center">{user.Role.description}</TableCell>
                  <TableCell align="center">{user.isActive ? 'Activo' : 'Desactivado'}</TableCell>
                  <TableCell align="center">
                    <IconButton onClick={() => handleEditUser(user)} color="primary">
                      <EditIcon />
                    </IconButton>
                    <IconButton >
                    <Button disabled={Number(userId) === user.id} onClick={() => handleDeleteUser(user.id)} color="error">
                    {user.isActive ? 'Desactivar' : 'Activar'}
                    </Button>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {isEditModalOpen && (
          <EditUserModal
            isOpen={isEditModalOpen}
            onClose={handleCloseEditModal}
            user={selectedUser}
            onSave={handleUserUpdated}
          />
        )}
      </div>
    </div>
  );
};

export default AdminUsers;
