import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

const EventDetailsModal = ({ isOpen, onClose, eventInfo, users }) => {
    // Función para formatear y copiar el texto de los detalles del evento
    const handleCopyDetails = () => {
        const details = `
Nombre del Evento: ${eventInfo.name}
Lugar de Concentracion: ${eventInfo.locationName}
Lugar del Evento: ${eventInfo.locationName} - ${eventInfo.locationAddress}
Fecha y Hora: ${eventInfo.date} a las ${eventInfo.time}
Participantes:
${users.map(user => `${user.username} - ${user.role === 'owner' ? 'Tecnico Encargado' : 'Tecnico'}`).join('\n')}
        `;
        navigator.clipboard.writeText(details)
            .then(() => alert('Detalles del evento copiados al portapapeles'))
            .catch(err => console.error('Error al copiar texto: ', err));
    };

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>Detalles del Evento Creado</DialogTitle>
            <DialogContent>
                <Typography variant="body1"><strong>Nombre del Evento:</strong> {eventInfo.name}</Typography>
                <Typography variant="body1"><strong>Lugar de Concentracion:</strong> {eventInfo.locationName} - {eventInfo.locationAddress}</Typography>
                <Typography variant="body1"><strong>Lugar del Evento:</strong> {eventInfo.concentrationPlaceName} - {eventInfo.concentrationPlaceAddress}</Typography>
                <Typography variant="body1"><strong>Fecha y Hora:</strong> {eventInfo.date} a las {eventInfo.time}</Typography>
                <Typography variant="body2"><strong>Participantes:</strong></Typography>
                {users.map(user => (
                    <Typography key={user.userId} variant="body2">
                        {user.username} - {user.role === 'owner' ? 'Tecnico Encargado' : 'Tecnico'}
                    </Typography>
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cerrar</Button>
                <Button onClick={handleCopyDetails} color="primary">Copiar Detalles</Button>
            </DialogActions>
        </Dialog>
    );
};

export default EventDetailsModal;
