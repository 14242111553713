import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, MenuItem, FormControl, InputLabel, Select, FormGroup, FormControlLabel, Checkbox, Paper, Snackbar } from '@mui/material';
import MuiAlert from '@mui/lab/Alert';
import EventDetailsModal from './EventDetailsModal'; // Asegúrate de tener este componente

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const EventManagementPanel = () => {
    const [users, setUsers] = useState([]);
    const [locations, setLocations] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedOwner, setSelectedOwner] = useState('');
    const [openAlert, setOpenAlert] = useState(false);
    const [eventData, setEventData] = useState({
        name: '',
        description: '',
        type: '',
        date: '',
        time: '',
        location: '',
        concentrationPlace: ''
    });
    const [eventCreated, setEventCreated] = useState(false);
    const [eventInfo, setEventInfo] = useState({});

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        try {
            const [userRes, locRes] = await Promise.all([
                axios.get(`${API_BASE_URL}/users`, { headers }),
                axios.get(`${API_BASE_URL}/locations`, { headers })
            ]);
            setUsers(userRes.data);
            setLocations(locRes.data);
            if (locRes.data.length > 0) {
                setEventData(prev => ({ ...prev, concentrationPlace: locRes.data[0].id }));
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEventData(prev => ({ ...prev, [name]: value }));
    };

    const handleUserChange = (userId) => {
        setSelectedUsers(prev =>
            prev.includes(userId) ? prev.filter(id => id !== userId) : [...prev, userId]
        );
    };

    const handleOwnerChange = (event) => {
        setSelectedOwner(event.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        try {
            const response = await axios.post(`${API_BASE_URL}/event-users`, {
                ...eventData,
                users: selectedUsers.map(userId => ({
                    userId,
                    eventRole: 'user' // Aquí asumo que todos los usuarios seleccionados tienen el rol de 'user' excepto el dueño
                })),
                owner: { userId: selectedOwner, eventRole: 'owner' }
            }, { headers });
            if (response.status === 200) {
                const newEventInfo = {
                    name: eventData.name,
                    locationName: locations.find(loc => loc.id === eventData.location).name,
                    locationAddress: locations.find(loc => loc.id === eventData.location).address,
                    concentrationPlaceName: locations.find(loc => loc.id === eventData.concentrationPlace).name,
                    concentrationPlaceAddress: locations.find(loc => loc.id === eventData.concentrationPlace).address,
                    date: eventData.date,
                    time: eventData.time,
                    participants: selectedUsers.map(userId => {
                        const user = users.find(u => u.id === userId);
                        return {
                            username: user.username,
                            email: user.email,
                            phoneNumber: user.phoneNumber,
                            role: userId === selectedOwner ? 'owner' : 'user'
                        };
                    })
                };

                setEventInfo(newEventInfo);
                setEventCreated(true);
                setOpenAlert(true);
                setEventData({
                    name: '',
                    description: '',
                    type: '',
                    date: '',
                    time: '',
                    location: '',
                    concentrationPlace: ''
                });
                setSelectedUsers([]);
                setSelectedOwner('');
            }
        } catch (error) {
            console.error('Error creating event:', error);
        }
    };


    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleCloseModal = () => {
        setEventCreated(false);
    };
    const depositoLocation = locations.find(location => location.name === "Deposito");
    const concentrationPlaceOptions = locations.filter(location =>
        location.id === depositoLocation?.id || location.id === eventData.location
    );
    return (
        <Paper style={{ padding: '20px', backgroundColor: '#fafafa' }} elevation={3}>
            <h2>Crear Evento</h2>
            <form onSubmit={handleSubmit}>
                <TextField
                    fullWidth
                    label="Nombre del evento"
                    name="name"
                    value={eventData.name}
                    onChange={handleChange}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Descripción del evento"
                    name="description"
                    value={eventData.description}
                    onChange={handleChange}
                    multiline
                    rows={4}
                    margin="normal"
                />
                <FormControl fullWidth margin="normal">
                    <InputLabel>Tipo de evento</InputLabel>
                    <Select
                        name="type"
                        value={eventData.type}
                        onChange={handleChange}
                    >
                        <MenuItem value="armado">Armado</MenuItem>
                        <MenuItem value="desarme">Desarme</MenuItem>
                        <MenuItem value="rearmado">Rearmado</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    fullWidth
                    type="date"
                    name="date"
                    value={eventData.date}
                    onChange={handleChange}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    type="time"
                    name="time"
                    value={eventData.time}
                    onChange={handleChange}
                    margin="normal"
                />
                <FormControl fullWidth margin="normal">
                    <InputLabel>Ubicación del evento</InputLabel>
                    <Select
                        name="location"
                        value={eventData.location}
                        onChange={handleChange}
                    >
                        {locations.map((location) => (
                            <MenuItem key={location.id} value={location.id}>
                                {location.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Lugar de concentración</InputLabel>
                    <Select
                        name="concentrationPlace"
                        value={eventData.concentrationPlace}
                        onChange={handleChange}
                        disabled={!eventData.location}  // Deshabilitar hasta que se seleccione una ubicación
                    >
                        {concentrationPlaceOptions.map((location) => (
                            <MenuItem key={location.id} value={location.id}>
                                {location.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <h3>Asignación para el Evento</h3>
                <FormGroup>
                    <h4>Técnicos para el Evento</h4>
                    {users.map((user) => (
                        <FormControlLabel
                            key={user.id}
                            control={
                                <Checkbox
                                    checked={selectedUsers.includes(user.id)}
                                    onChange={() => handleUserChange(user.id)}
                                />
                            }
                            label={user.username}
                        />
                    ))}
                </FormGroup>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Técnico Encargado del Evento</InputLabel>
                    <Select
                        value={selectedOwner}
                        onChange={handleOwnerChange}
                        displayEmpty
                    >
                        {selectedUsers.map(userId => (
                            <MenuItem key={userId} value={userId}>
                                {users.find(user => user.id === userId)?.username}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
                    Crear Evento
                </Button>
            </form>
            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity="success">
                    Evento creado con éxito!
                </Alert>
            </Snackbar>
            {eventCreated && (
                <EventDetailsModal
                    isOpen={eventCreated}
                    onClose={handleCloseModal}
                    eventInfo={eventInfo}
                    users={eventInfo.participants}
                />
            )}
        </Paper>
    );

};

export default EventManagementPanel;
