import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const EditUserModal = ({ isOpen, onClose, user, onSave }) => {
  const [userData, setUserData] = useState({
    username: '',
    email: '',
    phoneNumber: '',
    role_id: ''
  });

  const roles = [
    { id: '1', name: 'Admin' },
    { id: '2', name: 'User' }
  ];

  useEffect(() => {
    if (isOpen && user) {
      setUserData({
        username: user.username || '',
        email: user.email || '',
        phoneNumber: user.phoneNumber || '',
        role_id: user.role_id || roles[0].id 
      });
    }
  }, [user, isOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${API_BASE_URL}/users/${user.id}`, userData, {
        headers: { Authorization: `Bearer ${token}` }
      });
      onSave(userData);
      onClose();
    } catch (error) {
      console.error('Error al actualizar el usuario:', error);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Editar Usuario</DialogTitle>
      <DialogContent>
        <TextField
          label="Nombre de Usuario"
          type="text"
          fullWidth
          variant="outlined"
          name="username"
          value={userData.username}
          onChange={handleChange}
          margin="dense"
        />
        <TextField
          label="Correo Electrónico"
          type="email"
          fullWidth
          variant="outlined"
          name="email"
          value={userData.email}
          onChange={handleChange}
          margin="dense"
        />
        <TextField
          label="Teléfono"
          type="text"
          fullWidth
          variant="outlined"
          name="phoneNumber"
          value={userData.phoneNumber}
          onChange={handleChange}
          margin="dense"
        />
        <FormControl fullWidth margin="dense">
          <InputLabel id="role-label">Rol</InputLabel>
          <Select
            labelId="role-label"
            name="role_id"
            value={userData.role_id}
            onChange={handleChange}
            label="Rol"
          >
            {roles.map((role) => (
              <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleSave}>Guardar</Button>
      </DialogActions>
    </Dialog>
  );
};    

export default EditUserModal;
