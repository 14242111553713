// src/App.js
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LoginForm from './LoginForm';
import UserLocation from './UserLocation';
import NavBar from './NavBar';
import LocationsPage from './LocationsPage';
import AdminUsers from './AdminUsers';
import Events from './EventManagementPanel';
import EventsTable from './EventsTable';
import { AuthProvider, useAuth } from './AuthContext';  // Asegúrate de importar correctamente
import ResetPasswordForm from './ResetPasswordForm';

const AppWrapper = () => {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
}

function App() {
  const { isAuthenticated, isAdmin } = useAuth();
  return (
    <BrowserRouter>
       {isAuthenticated && (<NavBar isAdmin={isAdmin}/>)}
       <div style={{ margin: '1%' }}>
      <Routes>
        {!isAuthenticated && (
          <>
          <Route path="/" element={<LoginForm />} />
          <Route path="/reset-password/:token" element={<ResetPasswordForm />} />
          </>
        )}

        {isAuthenticated ? (
          <>
            <Route path="/user" element={<UserLocation />} />

            {isAdmin && (
              <>
                <Route path="/admin/locations" element={<LocationsPage />} />
                <Route path="/admin/users" element={<AdminUsers />} />
                <Route path="/admin/events" element={<Events />} />
                <Route path="/admin/events/list" element={<EventsTable />} />
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </Routes>
      </div>
    </BrowserRouter>
  );
}

export default AppWrapper;
