import React, { useState } from 'react';
import axios from 'axios';
import { Modal, Box, TextField, Button, Typography } from '@mui/material';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const AddLocationForm = ({ isOpen, onClose, onLocationAdded }) => {
  const [location, setLocation] = useState({
    name: '',
    description: '',
    address: '',
    latitud: '',
    longitud: ''
  });

  const handleChange = (e) => {
    setLocation({
      ...location,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${API_BASE_URL}/locations`, location, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }
      });
      alert('Ubicación agregada con éxito');
      onLocationAdded();
      onClose(); // Cierra el modal tras la adición exitosa
    } catch (error) {
      alert('Hubo un error al agregar la ubicación');
      console.error(error);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Agregar Nueva Ubicación
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            label="Nombre de la Ubicación"
            name="name"
            value={location.name}
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            label="Descripción"
            name="description"
            multiline
            rows={4}
            value={location.description}
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            label="Dirección"
            name="address"
            value={location.address}
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            label="Latitud"
            name="latitud"
            type="number"
            step="0.000001"
            value={location.latitud}
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            label="Longitud"
            name="longitud"
            type="number"
            step="0.000001"
            value={location.longitud}
            onChange={handleChange}
            required
          />
          <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
            Agregar Ubicación
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default AddLocationForm;
