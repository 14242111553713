import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Box, Container, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import { useAuth } from './AuthContext';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const LoginForm = () => {
  const [credentials, setCredentials] = useState({
    email: '',
    password: ''
  });
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');

  const navigate = useNavigate();
  const { login } = useAuth();

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value
    });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_BASE_URL}/login`, credentials);
      if (response.data.token) {
        login(response.data.token, response.data.user.Role.name, response.data.user.id, response.data.user.name);
        navigate('/user');
      } else {
        throw new Error('No token received');
      }
    } catch (error) {
      console.error('Error en el inicio de sesión:', error);
      alert('Error en el inicio de sesión');
    }
  };

  const handleForgotPassword = async () => {
    try {
      await axios.post(`${API_BASE_URL}/forgot-password`, { email });
      alert('Revisa tu correo electrónico para las instrucciones');
      setOpen(false);
    } catch (error) {
      console.error('Error al enviar el correo para restablecer contraseña:', error);
      alert('Error al solicitar el restablecimiento de contraseña');
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container maxWidth="xs">
      <Box sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 3,
        backgroundColor: 'background.paper',
        borderRadius: 1,
        boxShadow: 3
      }}>
        <Typography component="h1" variant="h5">
          Iniciar Sesión
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={credentials.email}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            autoComplete="current-password"
            value={credentials.password}
            onChange={handleChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Iniciar Sesión
          </Button>
          <Button
            onClick={handleClickOpen}
            fullWidth
            sx={{ mt: 1 }}
          >
            ¿Olvidaste tu contraseña?
          </Button>
        </Box>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Restablecer Contraseña</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Para restablecer tu contraseña, por favor, ingresa tu dirección de correo electrónico aquí.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Dirección de Correo Electrónico"
            type="email"
            fullWidth
            variant="standard"
            onChange={handleEmailChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleForgotPassword}>Enviar</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default LoginForm;
