import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const EventsTable = () => {
    const [events, setEvents] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedEventDetails, setSelectedEventDetails] = useState(null);
    const handleOpenModal = async (eventId) => {
        try {
            const response = await axios.get(`${API_BASE_URL}/events/${eventId}/details`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            setSelectedEventDetails(response.data);
            setIsModalOpen(true);
        } catch (error) {
            console.error('Error al obtener detalles del evento:', error);
        }
    };

    const fetchEvents = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/events`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            setEvents(response.data);
        } catch (error) {
            console.error('Error al obtener los eventos:', error);
        }
    };
    useEffect(() => {

        fetchEvents();
    }, []);

    const handleDeleteEvent = (eventId) => {
        if (window.confirm('¿Estás seguro de querer eliminar este evento?')) {
          axios.delete(`${API_BASE_URL}/events/${eventId}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          })
            .then(() => {
              alert('Evento eliminada exitosamente');
              fetchEvents();
            })
            .catch(error => {
              console.error('Hubo un error al eliminar el evento:', error);
              alert('No se pudo eliminar el evento');
            });
        }
      };
    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Nombre del Evento</TableCell>
                            <TableCell align="center">Tipo</TableCell>
                            <TableCell align="center">Fecha</TableCell>
                            <TableCell align="center">Hora</TableCell>
                            <TableCell align="center">Lugar de Concentración</TableCell>
                            <TableCell align="center">Editar</TableCell>
                            <TableCell align="center">Eliminar</TableCell>
                            <TableCell align="center">Ver Más</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {events.map((event) => (
                            <TableRow
                                key={event.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {event.name}
                                </TableCell>
                                <TableCell align="center">{event.eventType}</TableCell>
                                <TableCell align="center">{event.date}</TableCell>
                                <TableCell align="center">{event.time}</TableCell>
                                <TableCell align="center">{event.concentration_place.name} - {event.concentration_place.address}</TableCell>
                                <TableCell align="center">
                                    <IconButton disabled color="primary">
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                                <TableCell align="center">
                                    <IconButton color="secondary" onClick={() => handleDeleteEvent(event.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                                <TableCell align="center">
                                    <IconButton color="primary" onClick={() => handleOpenModal(event.id)}>
                                        <VisibilityIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                    <Typography id="modal-modal-title" sx={{ fontWeight: 'bold' }} variant="h4" component="h2">
                    {selectedEventDetails?.name}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {selectedEventDetails && (
                            <>
                                <Typography variant="body2" gutterBottom>
                                    Fecha: {selectedEventDetails.date}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Hora: {selectedEventDetails.time}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Tipo: {selectedEventDetails.eventType}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Lugar de concentracion: {selectedEventDetails.concentration_place.name} - {selectedEventDetails.concentration_place.address}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Lugar del evento: {selectedEventDetails.location.name} - {selectedEventDetails.location.address}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Descripción: {selectedEventDetails.description}
                                </Typography>
                                <Typography variant="h4" gutterBottom>
                                    Personal a asistir
                                </Typography>
                                <TableContainer component={Paper} sx={{ mt: 2 }}>
                                    <Table aria-label="detalles del evento">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Nombre</TableCell>
                                                <TableCell align="center">Rol</TableCell>
                                                <TableCell align="center">CheckIn</TableCell>
                                                <TableCell align="center">CheckOut</TableCell>
                                                <TableCell align="center">Fecha y Hora CheckIn</TableCell>
                                                <TableCell align="center">Fecha y Hora CheckOut</TableCell>
                                                <TableCell align="center">Duracion</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectedEventDetails.users.map((user) => (
                                                <TableRow key={user.id}>
                                                    <TableCell component="th" scope="row">
                                                        {user.username}
                                                    </TableCell>
                                                    <TableCell align="center">{user.role === 'owner' ? 'Tecnico Encargado' : 'Tecnico'}</TableCell>
                                                    <TableCell align="center">{user.userEvent.checkIn ? 'Sí' : 'No'}</TableCell>
                                                    <TableCell align="center">{user.userEvent.checkOut ? 'Sí' : 'No'}</TableCell>
                                                    <TableCell align="center">{user.userEvent.checkInDate}</TableCell>
                                                    <TableCell align="center">{user.userEvent.checkOutDate}</TableCell>
                                                    <TableCell align="center">{user.userEvent.duration}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        )}
                    </Typography>
                </Box>
            </Modal>
        </>
    );
};

export default EventsTable;
