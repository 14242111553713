import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  useTheme,
  useMediaQuery
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuth } from './AuthContext';


const NavBar = ({ isAdmin }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  const handleLogout = () => {
    logout(navigate);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <List>
      {isAdmin && (
        <>
          <ListItemButton component={RouterLink} to="/admin/locations">
            <ListItemText primary="Ubicaciones" />
          </ListItemButton>
          <ListItemButton component={RouterLink} to="/admin/users">
            <ListItemText primary="Usuarios" />
          </ListItemButton>
          <ListItemButton component={RouterLink} to="/admin/events">
            <ListItemText primary="Crear Eventos" />
          </ListItemButton>
          <ListItemButton component={RouterLink} to="/admin/events/list">
            <ListItemText primary="Listar Eventos" />
          </ListItemButton>
        </>
      )}
      <ListItemButton component={RouterLink} to="/user">
        <ListItemText primary="Mi Perfil" />
      </ListItemButton>
      <ListItemButton onClick={handleLogout}>
        <ListItemText primary="Log Out" />
      </ListItemButton>
    </List>
  );

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          {isMobile && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            E.S.T.E. Eventos S.R.L.
          </Typography>
          {!isMobile && (
            <>
              {isAdmin && (
                <>
                  <Button color="inherit" component={RouterLink} to="/admin/locations">Ubicaciones</Button>
                  <Button color="inherit" component={RouterLink} to="/admin/users">Usuarios</Button>
                  <Button color="inherit" component={RouterLink} to="/admin/events">Crear Eventos</Button>
                  <Button color="inherit" component={RouterLink} to="/admin/events/list">Listar Eventos</Button>
                </>
              )}
              <Button color="inherit" component={RouterLink} to="/user">Mi Perfil</Button>
              <Button variant="contained" color="error" onClick={handleLogout}>Log Out</Button>
            </>
          )}
        </Toolbar>
      </AppBar>
      {isMobile && (
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
          }}
        >
          {drawer}
        </Drawer>
      )}
    </div>
  );
};

export default NavBar;

